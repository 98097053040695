export const formatAddress = (address: string): string => {
  return `${address.slice(0, 7)}...${address.slice(-4)}`;
};

export const post = async (urls: string | string[], body: any): Promise<any> => {
  const fetchData = {
    method: 'POST',
    body,
  };
  try {
    const response = await fetch(Array.isArray(urls) ? urls[0] : urls, fetchData);
    return response.json();
  } catch (e) {
    console.log(e);
    return e;
  }
};
