import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { wallet } from '@cityofzion/neon-js';
import {
  getBalance,
  getBlock,
  readContract,
  switchChain,
  waitForTransactionReceipt,
  writeContract,
} from '@wagmi/core';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { NeoContext } from 'App';
import { message, Modal, Select, Tooltip } from 'antd';
import BigNumber from 'bignumber.js';
import delay from 'delay';
import { FC, useContext, useEffect, useState } from 'react';
import { decodeEventLog, formatUnits, parseUnits } from 'viem';
import { useAccount } from 'wagmi';
import { ReactComponent as Arrow } from 'assets/images/arrow.svg';
import bg from 'assets/images/bg.png';
import closeIcon from 'assets/images/close.svg';
import confirmIcon from 'assets/images/confirm.svg';
import dot1 from 'assets/images/dot1.svg';
import dot2 from 'assets/images/dot2.svg';
import dot3 from 'assets/images/dot3.svg';
import flmIcon from 'assets/images/flm.svg';
import gasIcon from 'assets/images/gas-icon.svg';
import jump from 'assets/images/jump.svg';
import loading from 'assets/images/loading.svg';
import logo from 'assets/images/logo.png';
import neoIcon from 'assets/images/neo.svg';
import tipIcon from 'assets/images/tip.svg';
import neoxIcon from 'assets/images/x.svg';
import { EvmWalletConnect } from 'components/EvmWalletConnect';
import { NeoWalletConnect } from 'components/NeoWalletConnect';
import { SUPPORTED_WALLETS_CONFIG } from 'components/NeoWalletConnect/walletsConfig';
import { flmAbi, neoAbi, neoxBridgeAbi } from 'utils/abis';
import getNetworkFee from 'utils/getNetworkFee';
import { formatAddress, post } from 'utils/tools';
import { publicClient, walletConfig } from 'utils/walletConfig';

export type Token = 'neo' | 'flm' | 'gas';

const N3_GAS_CONTRACT = process.env.REACT_APP_GAS_CONTRACT as string;
const N3_NEO_CONTRACT = process.env.REACT_APP_NEO_CONTRACT as string;
const N3_FLM_CONTRACT = process.env.REACT_APP_FLM_CONTRACT as string;
const BRIDGE_CONTRACT = process.env.REACT_APP_NEO_BRIDGE_CONTRACT as string;
const NEO_RPC = process.env.REACT_APP_NEO_RPC as string;
const NEOX_CHAIN_ID = process.env.REACT_APP_NEOX_CHAIN_ID as string;
const NEOX_CONTRACT = process.env.REACT_APP_NEOX_BRIDGE_CONTRACT as `0x${string}`;
const NEOX_NEO_CONTRACT = process.env.REACT_APP_NEOX_NEO_CONTRACT as `0x${string}`;
const NEOX_FLM_CONTRACT = process.env.REACT_APP_NEOX_FLM_CONTRACT as `0x${string}`;
const NEOX_BLOCK_EXPLORER = process.env.REACT_APP_NEOX_BLOCK_EXPLORER as string;
const NEO_BLOCK_EXPLORER = process.env.REACT_APP_NEO_BLOCK_EXPLORER as string;
const NEO_FURA = process.env.REACT_APP_NEO_FURA as string;
const N3_NETWORK = Number(process.env.REACT_APP_N3_NETWORK ?? 3);
const NEOX_API = process.env.REACT_APP_NEOX_API as string;

export const Token_MAP: {
  [K in Token]: {
    neoxContract: `0x${string}`;
    n3Contract: string;
    abi: any;
    n3Decimals: number;
    neoxDecimals: number;
  };
} = {
  flm: {
    neoxContract: NEOX_FLM_CONTRACT,
    n3Contract: N3_FLM_CONTRACT,
    abi: flmAbi,
    n3Decimals: 8,
    neoxDecimals: 8,
  },
  neo: {
    neoxContract: NEOX_NEO_CONTRACT,
    n3Contract: N3_NEO_CONTRACT,
    abi: neoAbi,
    n3Decimals: 0,
    neoxDecimals: 18,
  },
  gas: {
    neoxContract: NEOX_CONTRACT,
    n3Contract: N3_GAS_CONTRACT,
    abi: neoxBridgeAbi,
    n3Decimals: 8,
    neoxDecimals: 18,
  },
};

export const Index: FC = () => {
  const [messageApi, contextHolder1] = message.useMessage();
  const { open } = useWeb3Modal();
  const { address: neoxAddress, chain: neoxNetwork } = useAccount();
  const {
    connect: connectN3Wallet,
    address: n3Address,
    gasBalance: n3GasBalance,
    network: n3Network,
    getGasBalance: getN3GasBalance,
    dapi: n3Dapi,
    module: n3WalletModule,
  } = useContext(NeoContext);

  const [neoxGasBalance, setNeoxGasBalance] = useState('0');
  const [gasInput, setGasInput] = useState('');
  const [type, setType] = useState('n3ToNeox');
  const [n3InputMax, setN3InputMax] = useState<string | null>(null);
  const [getN3InputMaxPending, setGetN3InputMaxPending] = useState(true);
  const [neoxInputMax, setNeoxInputMax] = useState<string | null>(null);
  const [n3DepositStatus, setN3DepositStatus] = useState('default'); // n3 -> neox
  const [neoxDepositStatus, setNeoxDepositStatus] = useState('default'); // n3 -> neox
  const [n3DepositTxId, setN3DepositTxId] = useState(''); // n3 -> neox
  const [neoxDepositTxId, setNeoxDepositTxId] = useState(''); // n3 -> neox
  const [neoxWithdrawHash, setNeoxWithdrawHash] = useState(''); // neox -> n3
  const [n3WithdrawTxId, setN3WithdrawTxId] = useState(''); // neox -> n3
  const [neoxWithdrawStatus, setNeoxWithdrawStatus] = useState('default'); // neox -> n3
  const [n3WithdrawStatus, setN3WithdrawStatus] = useState('default'); // neox -> n3
  const [n3DepositFee, setN3DepositFee] = useState('0');
  const [contractMaxGasDeposit, setContractMaxGasDeposit] = useState('0');
  const [neoxWithdrawFee, setNeoxWithdrawFee] = useState('0');
  const [contractMaxNeoxDepositFee, setContractMaxNeoxDepositFee] = useState('0');
  const [selectedToken, setSelecteToken] = useState<Token>('gas');
  const [n3TokenBalance, setN3TokenBalance] = useState('0');
  const [neoxTokenBalance, setNeoxTokenBalance] = useState('0');
  const [isApproving, setIsApproving] = useState(false);
  const [maxTotalDeposit, setMaxTotalDeposit] = useState('0');
  const [totalDeposit, setTotalDeposit] = useState('0');

  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    getN3DepositFee();
    getContractMaxGasDeposit();
    getNeoXDepositFee();
    getGasBridge();
  }, []);

  useEffect(() => {
    updateNeoxBalance(neoxAddress);
  }, [neoxNetwork, neoxAddress, selectedToken, type]);

  useEffect(() => {
    getN3TokenBalance(n3Address);
  }, [n3Network, n3Address]);

  useEffect(() => {
    updateN3Balance(n3Address);
  }, [selectedToken, type]);

  useEffect(() => {
    getNeoxInputMax(selectedToken === 'gas' ? neoxGasBalance : neoxTokenBalance);
  }, [neoxGasBalance, neoxWithdrawFee, contractMaxNeoxDepositFee, selectedToken, neoxTokenBalance]);

  useEffect(() => {
    getN3InputMax(selectedToken === 'gas' ? n3GasBalance : n3TokenBalance);
  }, [n3GasBalance, n3DepositFee, contractMaxGasDeposit, selectedToken, n3TokenBalance]);

  useEffect(() => {
    setGasInput('');
  }, [n3Address, neoxAddress, n3Network, neoxNetwork, type, selectedToken]);

  const showWallets = () => {
    const walletModal = modal.info({
      icon: null,
      width: 400,
      footer: null,
      maskClosable: true,
      content: (
        <div className="w-full pb-[20px]">
          <div className="flex items-center justify-between">
            <div className="font-mont text-[24px] font-medium">Connect Wallet</div>
            <img src={closeIcon} className="cursor-pointer" onClick={() => walletModal.destroy()} />
          </div>
          {SUPPORTED_WALLETS_CONFIG.map(config => (
            <div
              key={config.name}
              className={`mt-[20px] flex cursor-pointer items-center gap-[10px] rounded-[10px] border-[1px] border-white50 bg-gray7 px-[30px] py-[20px] duration-200 hover:border-green2 ${
                config.name === 'Neon'
                  ? 'lg:hidden'
                  : config.name === 'OneGate'
                  ? 'hidden lg:flex'
                  : ''
              }`}
              onClick={() => {
                connectN3Wallet(config.name);
                walletModal.destroy();
              }}
            >
              <img src={config.icon} className="h-[24px] w-[24px]" />
              <div className="font-mont text-[17px] font-semibold">{config.name}</div>
            </div>
          ))}
        </div>
      ),
    });
  };

  const getN3DepositFee = async () => {
    try {
      const body = JSON.stringify({
        params: [BRIDGE_CONTRACT, 'gasDepositFee'],
        method: 'invokefunction',
        jsonrpc: '2.0',
        id: 1,
      });
      const res = await post(NEO_RPC, body);
      setN3DepositFee(
        BigNumber(res.result.stack[0].value).shiftedBy(-Token_MAP['gas'].n3Decimals).toString(),
      );
    } catch (e) {
      console.log(e);
    }
  };

  const getContractMaxGasDeposit = async () => {
    try {
      const body = JSON.stringify({
        params: [BRIDGE_CONTRACT, 'maxGasDeposit'],
        method: 'invokefunction',
        jsonrpc: '2.0',
        id: 1,
      });
      const res = await post(NEO_RPC, body);
      setContractMaxGasDeposit(
        BigNumber(res.result.stack[0].value).shiftedBy(-Token_MAP['gas'].n3Decimals).toString(),
      );
    } catch (e) {
      console.log(e);
    }
  };

  const getNeoXDepositFee = async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const result: any = await readContract(walletConfig, {
        abi: neoxBridgeAbi,
        address: NEOX_CONTRACT,
        functionName: 'gasBridge',
      });
      setNeoxWithdrawFee(formatUnits(result?.[3]?.fee ?? '0', Token_MAP['gas'].neoxDecimals));
      setContractMaxNeoxDepositFee(
        formatUnits(result?.[3]?.maxAmount ?? '0', Token_MAP['gas'].neoxDecimals),
      );
    } catch (e) {
      console.log(e);
    }
  };

  // Maximum available funds for gas on n3
  const getN3InputMax = async (balance: string) => {
    if (!n3Dapi || !n3Address) {
      return;
    }
    if (selectedToken === 'gas') {
      if (BigNumber(balance).minus(n3DepositFee).isLessThan(1)) {
        setN3InputMax(balance);
        setGetN3InputMaxPending(false);
        return;
      }
      const max = BigNumber(balance).minus(n3DepositFee).minus(0.1);
      setN3InputMax(
        max.comparedTo(0) >= 0 ? BigNumber.min(max, contractMaxGasDeposit).toString() : '0',
      );
      setGetN3InputMaxPending(true);
      try {
        const fromScriptHash = n3Dapi.AddressToScriptHash
          ? (await n3Dapi.AddressToScriptHash({ address: n3Address })).scriptHash
          : n3WalletModule?.AddressToScriptHash(n3Address);
        const from = { type: 'Hash160', value: fromScriptHash };
        const to = {
          type: 'Hash160',
          value: neoxAddress ?? '0x0000000000000000000000000000000000000000',
        };
        const amount = {
          type: 'Integer',
          value: BigNumber(2).multipliedBy(100000000).toString(),
        };
        const maxFee = {
          type: 'Integer',
          value: BigNumber(n3DepositFee).multipliedBy(100000000).toString(),
        };
        const signer = {
          account: fromScriptHash,
          scopes: 16,
          allowedContracts: [BRIDGE_CONTRACT, N3_GAS_CONTRACT],
          allowedGroups: [],
        };
        const invokeObj = [BRIDGE_CONTRACT, 'depositGas', [from, to, amount, maxFee], [signer]];
        const fetchInitData = {
          method: 'POST',
          body: JSON.stringify({
            params: invokeObj,
            method: 'invokefunction',
            jsonrpc: '2.0',
            id: 1,
          }),
        };
        const res = await fetch(NEO_RPC, fetchInitData);
        const systemFee = (await res.json())?.result?.gasconsumed;
        const pkRes = n3Dapi.getPublicKey ? await n3Dapi.getPublicKey() : '';
        const networkFee = !pkRes
          ? 0
          : await getNetworkFee(from, to, amount, maxFee, fromScriptHash, pkRes.publicKey);
        const totalFee = BigNumber(systemFee ?? 0)
          .plus(networkFee ?? 0)
          .shiftedBy(-Token_MAP['gas'].n3Decimals)
          .plus(0.03);
        const max = BigNumber(balance).minus(totalFee);
        setN3InputMax(
          max.minus(n3DepositFee).comparedTo(0) >= 0
            ? BigNumber.min(max, contractMaxGasDeposit).toString()
            : '0',
        );
        setGetN3InputMaxPending(false);
      } catch (err) {
        console.log(err);
        const max = BigNumber(balance).minus(n3DepositFee).minus(0.1);
        setN3InputMax(
          max.comparedTo(0) >= 0 ? BigNumber.min(max, contractMaxGasDeposit).toString() : '0',
        );
        setGetN3InputMaxPending(false);
      }
    } else {
      setN3InputMax(balance);
    }
  };

  const getNeoxGasBalance = async (address?: `0x${string}`) => {
    if (address != null) {
      try {
        const res = await getBalance(walletConfig, {
          address,
        });
        const balance = formatUnits(res.value, res.decimals);
        setNeoxGasBalance(BigNumber(balance).decimalPlaces(Token_MAP['gas'].n3Decimals).toString());
      } catch (e) {
        console.log(e);
      }
    } else {
      setNeoxGasBalance('0');
    }
  };

  const getNeoxTokenBalance = async (address?: `0x${string}`) => {
    if (selectedToken !== 'gas' && address != null) {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const balance: any = await readContract(walletConfig, {
          abi: Token_MAP[selectedToken].abi,
          address: Token_MAP[selectedToken].neoxContract,
          functionName: 'balanceOf',
          args: [address],
        });
        setNeoxTokenBalance(
          BigNumber(balance)
            .shiftedBy(-Token_MAP[selectedToken].neoxDecimals)
            .decimalPlaces(Token_MAP[selectedToken].n3Decimals)
            .toString(),
        );
      } catch (e) {
        console.log(e);
      }
    }
  };

  const updateNeoxBalance = async (address?: `0x${string}`) => {
    getNeoxGasBalance(address);
    getNeoxTokenBalance(address);
  };

  const getN3TokenBalance = async (address?: `0x${string}`) => {
    if (selectedToken !== 'gas' && address != null) {
      const balance = await n3WalletModule?.getNep17Balance(
        Token_MAP[selectedToken].n3Contract,
        address,
      );
      setN3TokenBalance(balance);
    }
  };

  const updateN3Balance = async (address?: `0x${string}`) => {
    getN3GasBalance();
    getN3TokenBalance(address);
  };

  const getNeoxInputMax = async (balance: string) => {
    if (!neoxAddress) {
      return;
    }
    if (selectedToken === 'gas') {
      if (BigNumber(balance).minus(neoxWithdrawFee).isLessThan(1)) {
        setNeoxInputMax(balance);
        return;
      }
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const gasUsed = await publicClient.estimateContractGas({
          address: NEOX_CONTRACT,
          abi: neoxBridgeAbi,
          functionName: 'withdrawGas',
          args: [
            `0x${wallet.getScriptHashFromAddress('NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN')}`,
            parseUnits('0.1', Token_MAP['gas'].neoxDecimals),
          ],
          value: BigInt(1100000000000000000),
          account: neoxAddress,
        });
        const block = await getBlock(walletConfig);
        const baseFeePerGas =
          block.baseFeePerGas != null
            ? formatUnits(block.baseFeePerGas, Token_MAP['gas'].neoxDecimals)
            : '0';
        const priorityFee = formatUnits(BigInt(10), Token_MAP['gas'].neoxDecimals / 2);
        const totalGasFee = BigNumber(baseFeePerGas)
          .plus(priorityFee)
          .times(gasUsed.toString())
          .plus(0.001);
        const max = BigNumber(balance).minus(totalGasFee);
        setNeoxInputMax(
          max.minus(neoxWithdrawFee).comparedTo(0) >= 0
            ? BigNumber.min(max, contractMaxNeoxDepositFee).toString()
            : '0',
        );
      } catch (e) {
        console.log(e);
        const max = BigNumber(balance).minus(neoxWithdrawFee).minus(0.001);
        setNeoxInputMax(
          max.comparedTo(0) >= 0 ? BigNumber.min(max, contractMaxNeoxDepositFee).toString() : '0',
        );
      }
    } else {
      setNeoxInputMax(balance);
    }
  };

  const deposit = async () => {
    if (n3Address === '') {
      showWallets();
      return;
    }
    if (n3Network.chainId !== N3_NETWORK) {
      if (n3Dapi.switchWalletNetwork) {
        n3Dapi.switchWalletNetwork({
          chainId: N3_NETWORK,
        });
      } else {
        messageApi.open({
          type: 'error',
          content: `Please switch the wallet network to N3 ${
            N3_NETWORK === 3 ? 'MainNet' : 'TestNet'
          }`,
          style: {
            position: 'relative',
            top: '40px',
          },
        });
      }
      return;
    }
    if (neoxAddress == null) {
      open();
      return;
    }
    if (selectedToken !== 'gas' && BigNumber(gasInput || 0).isLessThan(1)) {
      messageApi.open({
        type: 'error',
        content: `Deposit amount shouldn't be less than 1 ${selectedToken.toUpperCase()}.`,
        style: {
          position: 'relative',
          top: '40px',
        },
      });
      return;
    }
    if (
      selectedToken === 'gas' &&
      BigNumber(gasInput || 0)
        .minus(n3DepositFee)
        .isLessThan(1)
    ) {
      messageApi.open({
        type: 'error',
        content: `Deposit amount shouldn't be less than ${BigNumber(1)
          .plus(n3DepositFee)
          .toString()} GAS`,
        style: {
          position: 'relative',
          top: '40px',
        },
      });
      return;
    }
    const confirmModal = modal.confirm({
      icon: null,
      width: 500,
      okText: 'Confirm',
      cancelButtonProps: { style: { display: 'inline-block' } },
      content: (
        <div className="w-full">
          <img src={confirmIcon} className="mx-auto sm:w-[40px]" />
          <div className="mt-[10px] text-center font-mont text-[30px] font-medium sm:text-[26px]">
            Confirmation
          </div>
          <div className="mt-[30px] text-center sm:mt-[20px] sm:text-[14px]">
            <span className="font-mont">Are you sure you want to deposit</span>
            <span className="mx-[4px] font-mont text-green2">
              {BigNumber(gasInput).toString()} {selectedToken.toUpperCase()}
            </span>
            <span className="font-mont">to :</span>
          </div>
          <div className="mx-auto mb-[30px] mt-[10px] flex w-full items-center justify-center gap-[8px] rounded-[10px] border-[1px] border-gray bg-black py-[15px] text-center font-mont sm:mb-[20px] sm:py-[10px] sm:text-[10px]">
            <img src={neoxIcon} className="h-[20px] sm:h-[16px]" />
            <span>{neoxAddress}</span>
          </div>
        </div>
      ),
      onOk: () => onConfirm(confirmModal),
    });
  };

  async function onConfirm(confirmModal: any) {
    try {
      confirmModal.update({
        cancelButtonProps: { style: { display: 'none' } },
      });
      const fromScriptHash = n3Dapi.AddressToScriptHash
        ? (await n3Dapi.AddressToScriptHash({ address: n3Address })).scriptHash
        : n3WalletModule?.AddressToScriptHash(n3Address);
      const token = {
        type: 'Hash160',
        value: Token_MAP[selectedToken].n3Contract,
      };
      const from = { type: 'Hash160', value: fromScriptHash };
      const to = { type: 'Hash160', value: neoxAddress };
      const amount = {
        type: 'Integer',
        value: BigNumber(gasInput).shiftedBy(Token_MAP[selectedToken].n3Decimals).toString(),
      };
      const maxFee = {
        type: 'Integer',
        value: BigNumber(0.1).multipliedBy(100000000).toString(),
      };
      const signer = {
        account: fromScriptHash,
        scopes: 16,
        allowedContracts: [BRIDGE_CONTRACT, N3_GAS_CONTRACT],
        allowedGroups: [],
      };
      const invokeObj: any = {
        scriptHash: BRIDGE_CONTRACT,
        fee: '0.0001',
      };
      if (selectedToken === 'gas') {
        invokeObj.operation = 'depositGas';
        invokeObj.args = [from, to, amount, maxFee];
      } else {
        invokeObj.operation = 'depositToken';
        invokeObj.args = [token, from, to, amount, maxFee];
        signer.allowedContracts.push(Token_MAP[selectedToken].n3Contract);
      }
      setNeoxDepositStatus('default');
      setN3DepositStatus('default');
      setN3DepositTxId('');
      setNeoxDepositTxId('');
      const res = n3Dapi.invoke
        ? await n3Dapi.invoke({ ...invokeObj, signers: [signer] })
        : await n3Dapi.invokeFunction({
            invocations: [invokeObj],
            signers: [signer],
            extraSystemFee: 0,
            extraNetworkFee: 0,
          });
      const txid = res.txid ?? res;
      setN3DepositTxId(txid);
      setN3DepositStatus('pending');
      getApplicationLog(txid);
    } catch (e: any) {
      if (e?.type !== 'CANCELED') {
        setN3DepositStatus('error');
      }
      messageApi.open({
        type: 'error',
        content: e?.description ?? e?.message,
        style: {
          position: 'relative',
          top: '40px',
        },
      });
    }
  }

  // 轮训获取日志
  async function getApplicationLog(txid: string) {
    let status = 0;
    let executions: any;
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const body = JSON.stringify({
        params: [txid],
        method: 'getapplicationlog',
        jsonrpc: '2.0',
        id: 1,
      });
      try {
        const res = await post(NEO_RPC, body);
        if (res?.result?.executions?.[0]?.vmstate === 'HALT') {
          status = 1;
          executions = res?.result?.executions;
        } else if (res?.result?.executions?.[0]?.vmstate === 'FAULT') {
          status = -1;
          executions = res?.result?.executions;
        }
      } catch (e) {
        console.log(e);
        status = 0;
      }
      if (!status) {
        await delay(10000 * 1.5);
        continue;
      }
      break;
    }
    if (status !== 0) {
      if (executions != null && executions?.[0]?.exception === null && status === 1) {
        setN3DepositStatus('success');
        const nonce =
          selectedToken === 'gas'
            ? executions[0].notifications?.find((v: any) => v.eventname === 'GasDeposit').state
                .value[0].value
            : executions[0].notifications?.find((v: any) => v.eventname === 'TokenDeposit').state
                .value[2].value;
        getMinted(Number(nonce));
      } else {
        setN3DepositStatus('error');
      }
      setGasInput('');
      updateN3Balance(n3Address);
      getGasBridge();
    }
  }

  function getMinted(nonce: number) {
    const EmptyHash = '0x' + '00'.repeat(32);
    setNeoxDepositStatus('pending');
    let isFetching = false;
    const timer = setInterval(async () => {
      if (isFetching) {
        return;
      }
      try {
        isFetching = true;
        const fetchUrl =
          selectedToken === 'gas'
            ? `${NEOX_API}/deposits/${nonce}`
            : `${NEOX_API}/deposits/${Token_MAP[selectedToken].neoxContract}/${nonce}`;
        const txid = (await fetch(fetchUrl).then(v => v.json()))?.txid;
        isFetching = false;
        if (txid != null && txid != EmptyHash) {
          setNeoxDepositTxId(txid);
          clearInterval(timer);
          setNeoxDepositStatus('success');
          updateNeoxBalance(neoxAddress);
        }
      } catch (e: any) {
        setNeoxDepositStatus('error');
        modal.error({
          title: 'Error',
          content: <div className="text-center">Can't get minted: {e.message}</div>,
        });
        clearInterval(timer);
      }
    }, 5000);
  }

  const withdraw = async () => {
    if (neoxAddress == null) {
      open();
      return;
    }
    if (neoxNetwork?.id !== parseInt(NEOX_CHAIN_ID)) {
      switchChain(walletConfig, { chainId: parseInt(NEOX_CHAIN_ID) });
      return;
    }
    if (n3Address === '') {
      showWallets();
      return;
    }
    if (selectedToken !== 'gas' && BigNumber(gasInput || 0).isLessThan(1)) {
      messageApi.open({
        type: 'error',
        content: `Withdraw amount shouldn't be less than 1 ${selectedToken.toUpperCase()}.`,
        style: {
          position: 'relative',
          top: '40px',
        },
      });
      return;
    }
    if (
      selectedToken === 'gas' &&
      BigNumber(gasInput || 0)
        .minus(neoxWithdrawFee)
        .isLessThan(1)
    ) {
      messageApi.open({
        type: 'error',
        content: `Withdraw amount shouldn't be less than ${BigNumber(1)
          .plus(neoxWithdrawFee)
          .toString()} GAS`,
        style: {
          position: 'relative',
          top: '40px',
        },
      });
      return;
    }
    const confirmModal = modal.confirm({
      icon: null,
      width: 500,
      okText: 'Confirm',
      cancelButtonProps: { style: { display: 'inline-block' } },
      content: (
        <div className="w-full">
          <img src={confirmIcon} className="mx-auto sm:w-[40px]" />
          <div className="mt-[10px] text-center font-mont text-[30px] font-medium sm:text-[26px]">
            Confirmation
          </div>
          <div className="mt-[30px] text-center sm:mt-[20px] sm:text-[14px]">
            <span className="font-mont">Are you sure you want to withdraw</span>
            <span className="mx-[4px] font-mont text-green2">
              {BigNumber(gasInput).toString()} {selectedToken.toUpperCase()}
            </span>
            <span className="font-mont">to :</span>
          </div>
          <div className="mx-auto mb-[30px] mt-[10px] flex w-full items-center justify-center gap-[8px] rounded-[10px] border-[1px] border-gray bg-black py-[15px] text-center font-mont sm:mb-[20px] sm:py-[10px] sm:text-[12px]">
            <img src={neoIcon} className="h-[20px] sm:h-[16px]" />
            <span>{n3Address}</span>
          </div>
        </div>
      ),
      onOk: () => confirmWithdraw(confirmModal),
    });
  };

  const confirmWithdraw = async (confirmModal: any) => {
    if (neoxAddress != null) {
      try {
        confirmModal.update({
          cancelButtonProps: { style: { display: 'none' } },
        });
        setNeoxWithdrawHash('');
        setN3WithdrawTxId('');
        setN3WithdrawStatus('default');
        setNeoxWithdrawStatus('default');
        let couldWithdraw = true;
        let params: any = {
          abi: neoxBridgeAbi,
          address: NEOX_CONTRACT,
        };
        if (selectedToken === 'gas') {
          params = {
            ...params,
            functionName: 'withdrawGas',
            args: [
              `0x${wallet.getScriptHashFromAddress(n3Address)}`,
              parseUnits('0.1', Token_MAP['gas'].neoxDecimals),
            ],
            value: parseUnits(gasInput, Token_MAP[selectedToken].neoxDecimals),
          };
        } else {
          params = {
            ...params,
            functionName: 'withdrawToken',
            args: [
              Token_MAP[selectedToken].neoxContract,
              `0x${wallet.getScriptHashFromAddress(n3Address)}`,
              parseUnits(gasInput, Token_MAP[selectedToken].neoxDecimals),
            ],
            value: parseUnits('0.1', Token_MAP['gas'].neoxDecimals),
          };
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const allowanceAmount: any = await readContract(walletConfig, {
            abi: Token_MAP[selectedToken].abi,
            address: Token_MAP[selectedToken].neoxContract,
            functionName: 'allowance',
            args: [neoxAddress, NEOX_CONTRACT],
          });
          if (
            BigNumber(
              formatUnits(allowanceAmount, Token_MAP[selectedToken].neoxDecimals),
            ).isLessThan(gasInput)
          ) {
            couldWithdraw = false;
            setIsApproving(true);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const approveHash = await writeContract(walletConfig, {
              abi: Token_MAP[selectedToken].abi,
              address: Token_MAP[selectedToken].neoxContract,
              functionName: 'approve',
              args: [NEOX_CONTRACT, parseUnits(gasInput, Token_MAP[selectedToken].neoxDecimals)],
            });
            const transactionReceipt = await waitForTransactionReceipt(walletConfig, {
              hash: approveHash,
              pollingInterval: 5_000,
            });
            setIsApproving(false);
            if (transactionReceipt.status === 'success') {
              confirmWithdraw(confirmModal);
            }
          }
        }
        if (couldWithdraw) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const withdrawHash = await writeContract(walletConfig, params);
          setNeoxWithdrawHash(withdrawHash);
          setNeoxWithdrawStatus('pending');
          getWithdrawResult(withdrawHash);
        }
      } catch (err: any) {
        setIsApproving(false);
        if (err.message.indexOf('User rejected') >= 0) {
          messageApi.open({
            type: 'error',
            content: 'User rejected the request.',
            style: {
              position: 'relative',
              top: '40px',
            },
          });
        } else {
          modal.error({
            title: 'Error',
            content: <div className="text-center">{err.message}</div>,
          });
        }
      }
    }
  };

  const getWithdrawResult = async (withdrawHash: `0x${string}`) => {
    try {
      const withdrawResult = await waitForTransactionReceipt(walletConfig, {
        hash: withdrawHash,
        pollingInterval: 5_000,
      });
      if (withdrawResult.status === 'success') {
        setNeoxWithdrawStatus('success');
        setN3WithdrawStatus('pending');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const res: any = decodeEventLog({
          abi: neoxBridgeAbi,
          data: selectedToken === 'gas' ? withdrawResult.logs[0].data : withdrawResult.logs[1].data,
          topics:
            selectedToken === 'gas' ? withdrawResult.logs[0].topics : withdrawResult.logs[1].topics,
          strict: false,
        });
        const nonce = res?.args?.nonce;
        let isFetching = false;
        const timer = setInterval(async () => {
          try {
            if (isFetching) {
              return;
            }
            isFetching = true;
            const res = await post(
              NEO_FURA,
              JSON.stringify({
                jsonrpc: '2.0',
                method: 'GetBridgeTxByNonce',
                params: { ContractHash: BRIDGE_CONTRACT, Nonce: Number(nonce) },
                id: 1,
              }),
            );
            isFetching = false;
            if (res.result != null) {
              if (res.result.Vmstate === 'HALT') {
                setN3WithdrawStatus('success');
              } else {
                setN3WithdrawStatus('error');
              }
              setN3WithdrawTxId(res.result.txid);
              clearInterval(timer);
              setGasInput('');
              updateNeoxBalance(neoxAddress);
              updateN3Balance(n3Address);
              getGasBridge();
            }
          } catch (e: any) {
            getGasBridge();
            setN3WithdrawStatus('error');
            modal.error({
              title: 'Error',
              content: <div className="text-center">Can't get withdrawn: {e.message}</div>,
            });
            clearInterval(timer);
          }
        }, 5000);
      } else {
        setNeoxWithdrawStatus('error');
      }
    } catch (err) {
      setNeoxWithdrawStatus('error');
    }
  };

  const getGasBridge = async () => {
    try {
      const body = JSON.stringify({
        params: [BRIDGE_CONTRACT, 'getGasBridge'],
        method: 'invokefunction',
        jsonrpc: '2.0',
        id: 1,
      });
      const res = await post(NEO_RPC, body);
      setMaxTotalDeposit(
        BigNumber(res.result.stack[0].value[4].value[4].value)
          .shiftedBy(-Token_MAP['gas'].n3Decimals)
          .toString(),
      );
      setTotalDeposit(
        BigNumber(res.result.stack[0].value[1].value)
          .shiftedBy(-Token_MAP['gas'].n3Decimals)
          .toString(),
      );
    } catch (e) {
      console.log(e);
    }
  };

  const selectOption = [
    {
      value: 'gas',
      label: (
        <div className="flex items-center gap-[6px]">
          <div className="flex h-[24px] w-[24px] shrink-0 items-center justify-center rounded-full bg-black">
            <img src={gasIcon} className="w-[12px]" />
          </div>
          <span className="shrink-0 font-bold leading-none">GAS</span>
        </div>
      ),
    },
  ];

  if (N3_NETWORK === 6) {
    selectOption.push(
      {
        value: 'neo',
        label: (
          <div className="flex items-center gap-[6px]">
            <div className="flex h-[24px] w-[24px] shrink-0 items-center justify-center rounded-full bg-black">
              <img src={neoIcon} className="w-[12px]" />
            </div>
            <span className="shrink-0 font-bold leading-none">NEO</span>
          </div>
        ),
      },
      {
        value: 'flm',
        label: (
          <div className="flex items-center gap-[6px]">
            <div className="flex h-[24px] w-[24px] shrink-0 items-center justify-center rounded-full bg-black">
              <img src={flmIcon} className="w-[12px]" />
            </div>
            <span className="shrink-0 font-bold leading-none">FLM</span>
          </div>
        ),
      },
    );
  }

  return (
    <div className="relative bg-black sm:px-[16px]">
      {contextHolder}
      {contextHolder1}
      <img src={bg} className="absolute left-0 right-0 top-[60px] sm:hidden" />
      <div className="z-1 relative mt-[20px] flex items-center justify-between sm:mt-[14px]">
        <div className="ml-[110px] shrink-0 sm:ml-[10px]">
          <img src={logo} className="h-[35px]" />
        </div>
        <div className="mr-[50px] flex items-center gap-[12px] sm:mr-[10px]">
          <NeoWalletConnect className="h-[30px] w-[156px] rounded-[6px] border-[1px] border-green bg-[rgba(191,245,199,0.15)] text-[12px] font-semibold text-green duration-200 hover:bg-green hover:text-black sm:w-[88px] sm:text-[9px]" />
          <EvmWalletConnect className="h-[30px] w-[156px] rounded-[6px] border-[1px] border-purple bg-[rgba(194,119,253,0.1)] text-[12px] font-semibold text-purple duration-200 hover:bg-purple hover:text-black sm:w-[88px] sm:text-[9px]" />
        </div>
      </div>
      <div className="mt-[52px] text-center text-[40px] font-medium sm:mt-[32px] sm:text-[34px]">
        Bridge
      </div>
      {N3_NETWORK === 3 && (
        <div className="z-1 relative mx-auto mt-[16px] w-[600px] rounded-[20px] bg-black1 bg-orange2 px-[28px] py-[16px] sm:w-full sm:px-[16px]">
          <div className="text-[22px] font-semibold text-orange">Footnote</div>
          <div className="mt-[8px] text-[12px] text-orange">
            Please note that for the initial phase of the Neo X main net launch, only the GAS
            bilaterally bridging service will be available. There will be a cap of{' '}
            {BigNumber(maxTotalDeposit).toFormat(0)} GAS bridged from N3 to X for the first weeks to
            ensure the bridge service operates properly and smoothly. This measure is in place to
            mitigate risks and ensure a seamless user experience. As the bridge service proves its
            stability, the provider will gradually lift the GAS amount cap and expand the service to
            include NEO tokens and other assets.
          </div>
          <div className="mt-[8px] flex items-center justify-between">
            <div className="text-[14px] font-medium">Bridged GAS</div>
            <div className="text-[14px] font-medium">
              {BigNumber(totalDeposit).toFormat(0)} / {BigNumber(maxTotalDeposit).toFormat(0)}
            </div>
          </div>
          <div className="mt-[8px] h-[8px] w-full bg-white30">
            <div
              className={`h-full bg-orange`}
              style={{
                width: `${
                  maxTotalDeposit !== '0'
                    ? BigNumber(totalDeposit)
                        .div(maxTotalDeposit)
                        .multipliedBy(100)
                        .toFixed(0)
                        .toString()
                    : '0'
                }%`,
              }}
            />
          </div>
          <div className="mt-[8px] text-right text-[14px]">
            {maxTotalDeposit !== '0'
              ? BigNumber(totalDeposit).div(maxTotalDeposit).multipliedBy(100).toFixed(2).toString()
              : '0'}
            %
          </div>
        </div>
      )}

      <div className="z-1 relative mx-auto mt-[18px] w-[600px] rounded-[20px] bg-black1 p-[28px] sm:w-full">
        <img src={dot1} className="absolute -left-[70px] top-[80px] sm:hidden" />
        <img src={dot2} className="absolute -left-[40px] top-[200px] sm:hidden" />
        <img src={dot3} className="absolute -left-[150px] top-[300px] sm:hidden" />
        <img src={dot2} className="absolute -bottom-[60px] -left-[70px] sm:hidden" />
        <img src={dot2} className="absolute -right-[70px] bottom-[30px] sm:hidden" />
        <img src={dot1} className="absolute -right-[170px] bottom-[100px] sm:hidden" />
        <div className="mb-[20px] flex items-center gap-[10px]">
          <div className="text-[20px] font-bold">Token</div>
          <Select
            value={selectedToken}
            style={{ width: 110, height: 36 }}
            onChange={v => setSelecteToken(v)}
            options={selectOption}
          />
        </div>
        <div className="flex min-h-[80px] items-center gap-[10px] rounded-[10px] border-[1px] border-gray bg-gray2 px-[16px] py-[12px]">
          <div className="shrink-0">
            <div className="mb-[2px] font-semibold text-white opacity-50 sm:text-[14px]">From</div>
            <div className="flex items-center justify-center gap-[6px] rounded-[6px] border-[1px] border-gray bg-black1 px-[13px] py-[5px] sm:px-[5px]">
              <div className="flex h-[24px] w-[24px] shrink-0 items-center justify-center rounded-full bg-black p-[5px] sm:h-[18px] sm:w-[18px]">
                <img className="w-full" src={type === 'n3ToNeox' ? neoIcon : neoxIcon} />
              </div>
              <span className="shrink-0 font-bold leading-none sm:text-[14px]">
                {type === 'n3ToNeox' ? 'Neo N3' : 'Neo X'}
              </span>
            </div>
          </div>
          {type === 'n3ToNeox' && (
            <>
              {n3Address !== '' && (
                <div className="ml-auto text-right">
                  {n3Address !== '' && (
                    <button
                      className={`ml-auto hidden text-[22px] font-bold text-purple sm:block sm:text-[20px] ${
                        getN3InputMaxPending && 'cursor-not-allowed'
                      }`}
                      onClick={
                        getN3InputMaxPending
                          ? undefined
                          : () =>
                              setGasInput(
                                n3InputMax
                                  ? BigNumber(n3InputMax)
                                      .decimalPlaces(Token_MAP[selectedToken].n3Decimals)
                                      .toString()
                                  : selectedToken !== 'gas'
                                  ? BigNumber(n3TokenBalance)
                                      .decimalPlaces(Token_MAP[selectedToken].n3Decimals)
                                      .toString()
                                  : BigNumber(n3GasBalance)
                                      .minus(n3DepositFee)
                                      .minus(0.1)
                                      .decimalPlaces(Token_MAP[selectedToken].n3Decimals)
                                      .toString(),
                              )
                      }
                    >
                      MAX
                    </button>
                  )}
                  <input
                    className="mb-[2px] max-w-[300px] bg-transparent text-right text-[24px] font-medium outline-none sm:max-w-[120px] sm:text-[16px]"
                    placeholder="Enter amount"
                    value={gasInput}
                    onInput={e => {
                      let value = e.currentTarget.value;
                      if (Token_MAP[selectedToken].n3Decimals === 0) {
                        value = value.replace(/[^\d]/g, '');
                      } else {
                        value = value.replace(/[^\d.]/g, '').replace(/(\.\d+)\.+/g, '$1');
                      }
                      if (value === '') {
                        setGasInput('');
                        return;
                      }
                      if (e.currentTarget.value[0] === '.') {
                        value = `0${value}`;
                      }
                      if (
                        e.currentTarget.value.indexOf('.') >= 0 &&
                        e.currentTarget.value.split('.')[1].length >
                          Token_MAP[selectedToken].n3Decimals
                      ) {
                        value = e.currentTarget.value.slice(0, -1);
                      }
                      if (n3InputMax != null && BigNumber(value).comparedTo(n3InputMax) >= 0) {
                        value = BigNumber(n3InputMax)
                          .decimalPlaces(Token_MAP[selectedToken].n3Decimals)
                          .toString();
                      } else if (
                        BigNumber(value).comparedTo(n3GasBalance) >= 0 &&
                        selectedToken === 'gas'
                      ) {
                        value = BigNumber(n3GasBalance)
                          .minus(n3DepositFee)
                          .minus(0.1)
                          .decimalPlaces(Token_MAP[selectedToken].n3Decimals)
                          .toString();
                      }
                      setGasInput(value);
                    }}
                  />
                  <div className="text-right text-[12px] font-medium text-white opacity-50">
                    Balance{' '}
                    {BigNumber(selectedToken === 'gas' ? n3GasBalance : n3TokenBalance).toString()}
                  </div>
                </div>
              )}
              {n3Address !== '' && (
                <button
                  className={`text-[22px] font-bold text-purple sm:hidden ${
                    getN3InputMaxPending && 'cursor-not-allowed'
                  }`}
                  onClick={
                    getN3InputMaxPending
                      ? undefined
                      : () =>
                          setGasInput(
                            n3InputMax
                              ? BigNumber(n3InputMax)
                                  .decimalPlaces(Token_MAP[selectedToken].n3Decimals)
                                  .toString()
                              : selectedToken !== 'gas'
                              ? BigNumber(n3TokenBalance)
                                  .decimalPlaces(Token_MAP[selectedToken].n3Decimals)
                                  .toString()
                              : BigNumber(n3GasBalance)
                                  .minus(n3DepositFee)
                                  .minus(0.1)
                                  .decimalPlaces(Token_MAP[selectedToken].n3Decimals)
                                  .toString(),
                          )
                  }
                >
                  MAX
                </button>
              )}
              {n3Address === '' && (
                <NeoWalletConnect className="ml-auto h-[30px] w-[156px] rounded-[6px] border-[1px] border-green bg-[rgba(191,245,199,0.15)] text-[12px] font-semibold text-green duration-200 hover:bg-green hover:text-black sm:w-[88px] sm:text-[9px]" />
              )}
            </>
          )}
          {type === 'neoxToN3' && (
            <>
              {neoxAddress != null && (
                <div className="ml-auto text-right">
                  {neoxAddress != null && (
                    <button
                      className="ml-auto hidden text-[22px] font-bold text-purple sm:block sm:text-[20px]"
                      onClick={() =>
                        setGasInput(
                          neoxInputMax
                            ? BigNumber(neoxInputMax)
                                .decimalPlaces(Token_MAP[selectedToken].n3Decimals)
                                .toString()
                            : selectedToken !== 'gas'
                            ? BigNumber(neoxTokenBalance)
                                .decimalPlaces(Token_MAP[selectedToken].n3Decimals)
                                .toString()
                            : BigNumber(neoxGasBalance)
                                .minus(neoxWithdrawFee)
                                .minus(0.1)
                                .decimalPlaces(Token_MAP[selectedToken].n3Decimals)
                                .toString(),
                        )
                      }
                    >
                      MAX
                    </button>
                  )}
                  <input
                    className="mb-[2px] max-w-[300px] bg-transparent text-right text-[24px] font-medium outline-none sm:max-w-[120px] sm:text-[16px]"
                    placeholder="Enter amount"
                    value={gasInput}
                    onInput={e => {
                      let value = e.currentTarget.value;
                      if (Token_MAP[selectedToken].n3Decimals === 0) {
                        value = value.replace(/[^\d]/g, '');
                      } else {
                        value = value.replace(/[^\d.]/g, '').replace(/(\.\d+)\.+/g, '$1');
                      }
                      if (value === '') {
                        setGasInput('');
                        return;
                      }
                      if (e.currentTarget.value[0] === '.') {
                        value = `0${value}`;
                      }
                      if (
                        e.currentTarget.value.indexOf('.') >= 0 &&
                        e.currentTarget.value.split('.')[1].length >
                          Token_MAP[selectedToken].n3Decimals
                      ) {
                        value = e.currentTarget.value.slice(0, -1);
                      }
                      if (neoxInputMax != null && BigNumber(value).comparedTo(neoxInputMax) >= 0) {
                        value = BigNumber(neoxInputMax)
                          .decimalPlaces(Token_MAP[selectedToken].n3Decimals)
                          .toString();
                      } else if (
                        BigNumber(value).comparedTo(neoxGasBalance) >= 0 &&
                        selectedToken === 'gas'
                      ) {
                        value = BigNumber(neoxGasBalance)
                          .minus(1)
                          .decimalPlaces(Token_MAP[selectedToken].n3Decimals)
                          .toString();
                      }
                      setGasInput(value);
                    }}
                  />
                  <div className="text-right text-[12px] font-medium text-white opacity-50">
                    Balance{' '}
                    {BigNumber(
                      selectedToken === 'gas' ? neoxGasBalance : neoxTokenBalance,
                    ).toString()}
                  </div>
                </div>
              )}
              {neoxAddress != null && (
                <button
                  className="text-[22px] font-bold text-purple sm:hidden"
                  onClick={() =>
                    setGasInput(
                      neoxInputMax
                        ? BigNumber(neoxInputMax)
                            .decimalPlaces(Token_MAP[selectedToken].n3Decimals)
                            .toString()
                        : selectedToken !== 'gas'
                        ? BigNumber(neoxTokenBalance)
                            .decimalPlaces(Token_MAP[selectedToken].n3Decimals)
                            .toString()
                        : BigNumber(neoxGasBalance)
                            .minus(neoxWithdrawFee)
                            .minus(0.1)
                            .decimalPlaces(Token_MAP[selectedToken].n3Decimals)
                            .toString(),
                    )
                  }
                >
                  MAX
                </button>
              )}
              {neoxAddress == null && (
                <EvmWalletConnect className="ml-auto h-[30px] w-[156px] rounded-[6px] border-[1px] border-purple bg-[rgba(194,119,253,0.1)] text-[12px] font-semibold text-purple duration-200 hover:bg-purple hover:text-black sm:w-[88px] sm:text-[9px]" />
              )}
            </>
          )}
        </div>
        <div
          className="mx-auto my-[10px] flex h-[30px] w-[30px] cursor-pointer items-center justify-center rounded-full border-[1px] border-gray4 bg-gray5 text-white duration-200 hover:bg-green hover:text-black"
          onClick={() => setType(type === 'n3ToNeox' ? 'neoxToN3' : 'n3ToNeox')}
        >
          <Arrow />
        </div>
        <div className="flex h-[80px] items-center gap-[10px] rounded-[10px] bg-gray2 px-[16px] py-[12px]">
          <div className="shrink-0">
            <div className="mb-[2px] font-semibold text-white opacity-50 sm:text-[14px]">To</div>
            <div className="flex items-center justify-center gap-[6px] rounded-[6px] border-[1px] border-gray bg-black1 px-[13px] py-[5px] sm:px-[5px]">
              <div className="flex h-[24px] w-[24px] shrink-0 items-center justify-center rounded-full bg-black p-[5px] sm:h-[18px] sm:w-[18px]">
                <img className="w-full" src={type === 'n3ToNeox' ? neoxIcon : neoIcon} />
              </div>
              <span className="shrink-0 font-bold leading-none sm:text-[14px]">
                {type === 'n3ToNeox' ? 'Neo X' : 'Neo N3'}
              </span>
            </div>
          </div>
          {type === 'n3ToNeox' && (
            <div className="ml-auto text-right text-[14px] font-medium text-white50">
              {neoxAddress ? (
                <>
                  <span className="sm:hidden">{neoxAddress}</span>
                  <span className="hidden sm:inline">{formatAddress(neoxAddress)}</span>
                </>
              ) : (
                <EvmWalletConnect className="ml-auto h-[30px] w-[156px] rounded-[6px] border-[1px] border-purple bg-[rgba(194,119,253,0.1)] text-[12px] font-semibold text-purple duration-200 hover:bg-purple hover:text-black sm:w-[88px] sm:text-[9px]" />
              )}
            </div>
          )}
          {type === 'neoxToN3' && (
            <div className="ml-auto text-right text-[14px] font-medium text-white50">
              {n3Address ? (
                <>
                  <span className="sm:hidden">{n3Address}</span>
                  <span className="hidden sm:inline">{formatAddress(n3Address)}</span>
                </>
              ) : (
                <NeoWalletConnect className="ml-auto h-[30px] w-[156px] rounded-[6px] border-[1px] border-green bg-[rgba(191,245,199,0.15)] text-[12px] font-semibold text-green duration-200 hover:bg-green hover:text-black sm:w-[88px] sm:text-[9px]" />
              )}
            </div>
          )}
        </div>
        <div className="mt-[18px] px-[20px] text-[14px] font-medium sm:px-0 sm:text-[12px]">
          <div className="flex items-center gap-[5px]">
            <div>Bridge Fee</div>
            <Tooltip
              title="The bridge contract charges 0.1 GAS, and the platform does not charge any other fees."
              placement="top"
              color="#9AE4A5"
              overlayInnerStyle={{
                color: 'black',
                padding: '20px 14px',
                marginLeft: '10px',
                width: '320px',
              }}
            >
              <img src={tipIcon} />
            </Tooltip>
            <div className="ml-auto">
              {type === 'n3ToNeox' ? n3DepositFee : neoxWithdrawFee} GAS
            </div>
          </div>
          <div className="mt-[16px] flex items-center justify-between">
            <div>Actual Receive</div>
            {selectedToken === 'gas' ? (
              <div>
                {BigNumber(gasInput)
                  .minus(type === 'n3ToNeox' ? n3DepositFee : neoxWithdrawFee)
                  .isGreaterThanOrEqualTo(1)
                  ? BigNumber(gasInput)
                      .minus(type === 'n3ToNeox' ? n3DepositFee : neoxWithdrawFee)
                      .toString()
                  : '-'}{' '}
                GAS
              </div>
            ) : (
              <div>
                {gasInput || '-'} {selectedToken.toUpperCase()}
              </div>
            )}
          </div>
        </div>
        {type === 'n3ToNeox' && (
          <button
            className={`mt-[18px] w-full rounded-[10px] border-[1px] border-green py-[12px] font-bold text-white duration-200  ${
              n3DepositStatus === 'pending' ||
              BigNumber(maxTotalDeposit).minus(totalDeposit).minus(n3DepositFee).isLessThan(1)
                ? 'cursor-not-allowed !border-white30 !text-white50'
                : 'hover:bg-green hover:text-black'
            }`}
            onClick={
              n3DepositStatus === 'pending' ||
              BigNumber(maxTotalDeposit).minus(totalDeposit).minus(n3DepositFee).isLessThan(1)
                ? undefined
                : deposit
            }
          >
            {BigNumber(maxTotalDeposit).minus(totalDeposit).minus(n3DepositFee).isLessThan(1)
              ? 'Insufficient Quota'
              : 'Deposit'}
          </button>
        )}
        {type === 'neoxToN3' && (
          <button
            className={`mt-[18px] w-full rounded-[10px] border-[1px] border-green py-[12px] font-bold text-white duration-200 ${
              neoxWithdrawStatus === 'pending'
                ? 'cursor-not-allowed'
                : 'hover:bg-green hover:text-black'
            }`}
            onClick={neoxWithdrawStatus === 'pending' ? undefined : withdraw}
          >
            Withdraw
          </button>
        )}
      </div>
      {type === 'n3ToNeox' && (
        <div className="z-1 relative mx-auto w-[600px] px-[28px] sm:w-full sm:px-0 sm:text-[12px]">
          {n3DepositStatus !== 'default' && (
            <div className="mt-[20px] flex items-center gap-[20px] sm:gap-[16px]">
              {n3DepositStatus === 'pending' && (
                <div className="flex items-center gap-[15px] sm:gap-[10px]">
                  <img src={loading} className="animate-spin sm:w-[20px]" />
                  <div className="text-white65 ">Depositing</div>
                </div>
              )}
              {n3DepositStatus === 'success' && (
                <div className="flex items-center gap-[15px] sm:gap-[10px]">
                  <CheckCircleOutlined
                    rev={undefined}
                    style={{ fontSize: '20px', color: '#BFF5C7' }}
                  />
                  <div className="text-white65 ">Deposited</div>
                </div>
              )}
              {n3DepositStatus === 'error' && (
                <div className="flex items-center gap-[15px] sm:gap-[10px]">
                  <CloseCircleOutlined style={{ fontSize: '20px', color: 'red' }} rev={undefined} />
                  <div className="text-white65 ">Fault</div>
                </div>
              )}
              {n3DepositTxId && n3DepositStatus !== 'pending' && (
                <div className="flex items-center gap-[10px]">
                  <a
                    className="block"
                    href={`${NEO_BLOCK_EXPLORER}/transactionInfo/${n3DepositTxId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View on OneGate Explorer
                  </a>
                  <img src={jump} className="sm:w-[12px]" />
                </div>
              )}
            </div>
          )}
          {neoxDepositStatus !== 'default' && (
            <div className="mt-[20px] flex items-center gap-[20px] sm:gap-[16px]">
              {neoxDepositStatus === 'pending' && (
                <div className="flex items-center gap-[15px] sm:gap-[10px]">
                  <img src={loading} className="animate-spin sm:w-[20px]" />
                  <div className="text-white65 ">Transferring</div>
                </div>
              )}
              {neoxDepositStatus === 'success' && (
                <div className="flex items-center gap-[15px] sm:gap-[10px]">
                  <CheckCircleOutlined
                    rev={undefined}
                    style={{ fontSize: '20px', color: '#BFF5C7' }}
                  />
                  <div className="text-white65 ">Transferred</div>
                </div>
              )}
              {neoxDepositStatus === 'error' && (
                <div className="flex items-center gap-[15px] sm:gap-[10px]">
                  <CloseCircleOutlined rev={undefined} style={{ fontSize: '20px', color: 'red' }} />
                  <div className="text-white65 ">Fault</div>
                </div>
              )}
              {neoxDepositTxId && n3DepositStatus !== 'pending' && (
                <div className="flex items-center gap-[10px]">
                  <a
                    className="block"
                    href={`${NEOX_BLOCK_EXPLORER}/tx/${neoxDepositTxId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View on Neo X Explorer
                  </a>
                  <img src={jump} className="sm:w-[12px]" />
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {type === 'neoxToN3' && (
        <div className="z-1 relative mx-auto w-[600px] px-[28px] sm:w-full sm:px-0 sm:text-[12px]">
          {isApproving && neoxWithdrawStatus === 'default' && (
            <div className="mt-[20px] flex items-center gap-[15px] sm:gap-[10px]">
              <img src={loading} className="animate-spin sm:w-[20px]" />
              <div className="text-white65 ">Approve NeoX</div>
            </div>
          )}
          <div className="mt-[20px] flex items-center gap-[20px] sm:gap-[16px]">
            {neoxWithdrawStatus === 'pending' && (
              <div className="flex items-center gap-[15px] sm:gap-[10px]">
                <img src={loading} className="animate-spin sm:w-[20px]" />
                <div className="text-white65 ">Withdrawing</div>
              </div>
            )}
            {neoxWithdrawStatus === 'success' && (
              <div className="flex items-center gap-[15px] sm:gap-[10px]">
                <CheckCircleOutlined
                  rev={undefined}
                  style={{ fontSize: '20px', color: '#BFF5C7' }}
                />
                <div className="text-white65 ">Withdrawn</div>
              </div>
            )}
            {neoxWithdrawStatus === 'error' && (
              <div className="flex items-center gap-[15px] sm:gap-[10px]">
                <CloseCircleOutlined style={{ fontSize: '20px', color: 'red' }} rev={undefined} />
                <div className="text-white65 ">Fault</div>
              </div>
            )}
            {neoxWithdrawHash !== '' && (
              <div className="flex items-center gap-[10px]">
                <a
                  className="block"
                  href={`${NEOX_BLOCK_EXPLORER}/tx/${neoxWithdrawHash}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  View on Neo X Explorer
                </a>
                <img src={jump} className="sm:w-[12px]" />
              </div>
            )}
          </div>
          {neoxWithdrawStatus === 'success' && (
            <div className="mt-[20px] flex items-center gap-[20px] sm:gap-[16px]">
              {n3WithdrawStatus === 'pending' && (
                <div className="flex items-center gap-[15px] sm:gap-[10px]">
                  <img src={loading} className="animate-spin sm:w-[20px]" />
                  <div className="text-white65 ">Transferring</div>
                </div>
              )}
              {n3WithdrawStatus === 'success' && (
                <div className="flex items-center gap-[15px] sm:gap-[10px]">
                  <CheckCircleOutlined
                    rev={undefined}
                    style={{ fontSize: '20px', color: '#BFF5C7' }}
                  />
                  <div className="text-white65 ">Transferred</div>
                </div>
              )}
              {n3WithdrawStatus === 'error' && (
                <div className="flex items-center gap-[15px] sm:gap-[10px]">
                  <CloseCircleOutlined rev={undefined} style={{ fontSize: '20px', color: 'red' }} />
                  <div className="text-white65 ">Fault</div>
                </div>
              )}
              {n3WithdrawTxId !== '' && (
                <div className="flex items-center gap-[10px]">
                  <a
                    className="block"
                    href={`${NEO_BLOCK_EXPLORER}/transactionInfo/${n3WithdrawTxId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    View on OneGate Explorer
                  </a>
                  <img src={jump} className="sm:w-[12px]" />
                </div>
              )}
            </div>
          )}
        </div>
      )}
      <div className="h-[30px] w-full" />
    </div>
  );
};
