import { useWeb3Modal } from '@web3modal/wagmi/react';
import { Popover } from 'antd';
import { FC, useState } from 'react';
import { useAccount, useDisconnect } from 'wagmi';
import { formatAddress } from 'utils/tools';

interface Props {
  className?: string;
}

export const EvmWalletConnect: FC<Props> = ({ className: _className }) => {
  const { address } = useAccount();
  const { disconnect } = useDisconnect();
  const { open } = useWeb3Modal();
  const [popoverOpen, setPopoverOpen] = useState(false);

  if (!address) {
    return (
      <>
        <button className={_className} onClick={() => open()}>
          Connect Neo X
        </button>
      </>
    );
  }
  return (
    <Popover
      open={popoverOpen}
      onOpenChange={open => setPopoverOpen(open)}
      trigger="click"
      placement="bottom"
      overlayInnerStyle={{ padding: 0 }}
      content={
        <div
          className="cursor-pointer rounded-[6px] border-[1px] border-gray px-[15px] py-[10px]"
          onClick={() => {
            disconnect();
            setPopoverOpen(false);
          }}
        >
          Disconnect
        </div>
      }
    >
      <button className="cursor-pointer rounded-[6px] bg-purple px-[10px] py-[7px] text-[12px] font-semibold text-black">
        {formatAddress(address)}
      </button>
    </Popover>
  );
};
