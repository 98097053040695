import EventEmitter from 'eventemitter3';
import { useEffect, useState } from 'react';
import { SUPPORTED_WALLETS_CONFIG, WalletConfig, WalletId } from './walletsConfig';

const N3_GAS_CONTRACT = process.env.REACT_APP_GAS_CONTRACT as string;

export const useConnectNeoWallet = (): any => {
  const [address, setAddress] = useState('');
  const [network, setNetwork] = useState({
    chainId: 0,
    chainName: '',
  });
  const [gasBalance, setGasBalance] = useState('0');
  const [walletConfig, setWalletConfig] = useState<WalletConfig>();

  const connect = async (name: WalletId) => {
    const walletConfig = SUPPORTED_WALLETS_CONFIG.find(config => config.name === name);
    setWalletConfig(walletConfig);
    const module = walletConfig?.module;
    getGasBalance();
    if (module?.dapi) {
      const address = await module?.getAccount();
      const network = await module?.getNetwork();
      setAddress(address);
      setNetwork(network);
    } else {
      window.open(walletConfig?.downloadUrl);
    }
  };

  const disconnect = async () => {
    const module = walletConfig?.module;
    module?.disconnect();
    setAddress('');
    setNetwork({
      chainId: 0,
      chainName: '',
    });
    setGasBalance('0');
  };

  const getGasBalance = async () => {
    const module = walletConfig?.module;
    if (module && address) {
      const results = await module.getNep17Balance(N3_GAS_CONTRACT, address);
      setGasBalance(results);
    }
  };

  useEffect(() => {
    window.EE = new EventEmitter();
    window.EE?.on('neoAccountChanged', (address: string) => {
      setAddress(address);
    });
    window.EE?.on('neoNetworkChanged', (network: any) => {
      setNetwork(network);
    });
    window.EE?.on('isReady', () => {
      if (localStorage.getItem('preConnectedNeoWallet')) {
        const walletConfig = SUPPORTED_WALLETS_CONFIG.find(
          config => config.name === localStorage.getItem('preConnectedNeoWallet'),
        );
        setWalletConfig(walletConfig);
        const module = walletConfig?.module;
        if (module?.dapi) {
          module?.getAccount().then((address: any) => setAddress(address));
          module?.getNetwork().then((network: any) => setNetwork(network));
        } else {
          window.open(walletConfig?.downloadUrl);
        }
      }
    });
  }, []);

  useEffect(() => {
    getGasBalance();
  }, [address, network]);

  return {
    connect,
    disconnect,
    address,
    gasBalance,
    network,
    getGasBalance,
    dapi: walletConfig?.module?.dapi,
    module: walletConfig?.module,
  };
};
