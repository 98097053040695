import { rpc, sc, tx, u, wallet } from '@cityofzion/neon-js';

const N3_GAS_CONTRACT = process.env.REACT_APP_GAS_CONTRACT as string;
const BRIDGE_CONTRACT = process.env.REACT_APP_NEO_BRIDGE_CONTRACT as string;
const NEO_RPC = process.env.REACT_APP_NEO_RPC as string;

// 获取网络费
const getNetworkFee = async (
  from: any,
  to: any,
  amount: any,
  maxFee: any,
  fromScriptHash: string,
  publicKey: string,
): Promise<string> => {
  try {
    const invocation = {
      scriptHash: BRIDGE_CONTRACT,
      operation: 'depositGas',
      args: [
        sc.ContractParam.fromJson(from),
        sc.ContractParam.fromJson(to),
        sc.ContractParam.fromJson(amount),
        sc.ContractParam.fromJson(maxFee),
      ],
    };
    const txSigners = [
      {
        account: fromScriptHash,
        scopes: 16,
        allowedContracts: [BRIDGE_CONTRACT, N3_GAS_CONTRACT],
        allowedGroups: [],
      },
    ];
    const script = sc.createScript(invocation);
    const rpcClient = new rpc.RPCClient(NEO_RPC);
    const currentHeight = await rpcClient.getBlockCount();
    const transaction = new tx.Transaction({
      signers: txSigners,
      validUntilBlock: currentHeight + 5000,
      script: script,
      witnesses: [
        {
          invocationScript: '',
          verificationScript: wallet.getVerificationScriptFromPublicKey(publicKey),
        },
      ],
    });
    const fetchInitData = {
      method: 'POST',
      body: JSON.stringify({
        params: [u.HexString.fromHex(transaction.serialize(true)).toBase64()],
        method: 'calculatenetworkfee',
        jsonrpc: '2.0',
        id: 1,
      }),
    };
    const response = await fetch(NEO_RPC, fetchInitData);
    const detail = await response?.json();
    const networkfee = detail?.result?.networkfee;
    return networkfee;
  } catch (err) {
    console.log(err);
    return '0';
  }
};

export default getNetworkFee;
