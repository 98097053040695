import neoline from 'assets/images/neoline.png';
import neon from 'assets/images/neon.svg';
import onegate from 'assets/images/onegate.png';

export enum WalletId {
  NeoLine = 'NeoLine',
  Neon = 'Neon',
  OneGate = 'OneGate',
}

export type WalletConfig = {
  name: WalletId;
  icon: string;
  downloadUrl: string;
  module?: any;
};

const WALLETS_MODULES = {
  [WalletId.NeoLine]: () => import('./neoline'),
  [WalletId.Neon]: () => import('./neon'),
  [WalletId.OneGate]: () => import('./onegate'),
};

export const SUPPORTED_WALLETS_CONFIG: WalletConfig[] = [
  {
    name: WalletId.NeoLine,
    icon: neoline,
    downloadUrl:
      'https://chrome.google.com/webstore/detail/neoline/cphhlgmgameodnhkjdmkpanlelnlohao',
  },
  {
    name: WalletId.Neon,
    icon: neon,
    downloadUrl: 'https://neonwallet.com',
  },
  {
    name: WalletId.OneGate,
    icon: onegate,
    downloadUrl: 'https://onegate.space',
  },
];

export const initWallets = (): void => {
  SUPPORTED_WALLETS_CONFIG.forEach(async (config, idx) => {
    const module = await WALLETS_MODULES[config.name]();
    module.initDapi();
    SUPPORTED_WALLETS_CONFIG[idx].module = module;
  });
};
